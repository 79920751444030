import { graphql, Link } from 'gatsby'
import React from 'react'
import Layout from '../../components/Layout'

export default function resources({ data }) {
    console.log(data)
    const videos = data.videos.nodes
    const articles = data.articles.nodes

    return (
        <Layout>
            <section className="container pb-5">
                <h1 className="has-text-light title">Resources</h1>
                <p className="subtitle has-text-light">Find the latest video and article resources from Top Talent.</p>
            </section>
            <section className="section pb-5">
                <div className="container">
                    <h1 className="title has-text-light">Videos</h1>
                    <div className="columns is-8 is-variable">
                        {videos.map(video => (
                            <div className="column is-2-tablet is-3-desktop">
                                <Link to={"/resources/" + video.frontmatter.slug} key={video.id}>
                                    <div className="card pt-2 pl-4 pr-4 pb-4 has-background-light">
                                        <h3 className="card-header-title title has-text-centered">{ video.frontmatter.title }</h3>
                                        <p className="subtitle has-text-centered">{"with: " + video.frontmatter.author }</p>
                                        {/* <p className="subtitle has-text-centered">{ resource.frontmatter.date }</p> */}
                                        <p className="is-centered">{ video.frontmatter.description }</p>
                                        <footer className="card-footer">
                                            <a href="#" className="card-footer-item">Read more...</a>
                                        </footer>
                                    </div>
                                </Link>   
                            </div>                         
                        ))}
                    </div>
                </div>
                
                
            </section>
            <section className="container pb-5">
                <div className="container">
                    <h1 className="title has-text-light">Articles</h1>
                    <div className="columns is-8 is-variable">
                        {articles.map(article => (
                            <div className="column is-2-tablet is-3-desktop">
                                <Link to={"/resources/" + article.frontmatter.slug} key={article.id}>
                                    <div className="card pt-2 pl-4 pr-4 pb-4 has-background-light">
                                        <h3 className="card-header-title title has-text-centered">{ article.frontmatter.title }</h3>
                                        {/* <p className="subtitle has-text-centered">{"by: " + article.frontmatter.author }</p> */}
                                        {/* <p className="subtitle has-text-centered">{ resource.frontmatter.date }</p> */}
                                        <p className="is-centered">{ article.frontmatter.description }</p>
                                        <footer className="card-footer">
                                            <a href="#" className="card-footer-item">Read more...</a>
                                        </footer>
                                    </div>
                                </Link>   
                            </div>                         
                        ))}
                    </div>
                </div>
            </section>
        </Layout>
    )
}

//export page query
export const query = graphql`
    query ResourcesPage {
        videos: allMarkdownRemark(
            filter: {frontmatter: {type: {eq: "Video"}}}
            sort: {order: DESC, fields: frontmatter___date}
          ) {
            nodes {
              frontmatter {
                slug
                title
                type
                author
                date(formatString: "MMMM DD, YYYY")
                description
              }
              id
            }
        }
        articles: allMarkdownRemark(
            filter: {frontmatter: {type: {eq: "Research Article"}}}
            sort: {order: DESC, fields: frontmatter___date}
            ) {
                nodes {
                    frontmatter {
                    slug
                    title
                    type
                    author
                    date(formatString: "MMMM DD, YYYY")
                    description
                    }
                    id
                }
        }
    }
`
